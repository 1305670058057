import { memo, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { MergeConditionsButton } from '../MergeConditionsButton';
import { MergeConditionsModal } from '../MergeConditionsModal';
import {
  isSidebarOpenedSelector,
  selectCondition,
  selectTermCategory,
  setUpdatedConditionIdToReview,
  setSelectedTerms,
  setShowSearchTermsWithNoResults,
  setSummaryHighlighted,
  setPreviousConditionIdToReview,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { conditionsSelector, selectedSimpleConditionSelector } from '@/pageAI/states/unified';
import { useUnifiedTab } from '@/pageAI/hooks/unified/useUnifiedTab';
import { posthog } from '@/shared/plugins/posthog';
import { ConditionTermCategory } from '@/pageAI/hooks/conditions/useConditionSearchTerms';
import { ConditionSidebarToggle } from '../ConditionSidebarToggle';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { ReviewNewVersionButton } from '../versionReview/ReviewNewVersionButton';
import { CurrentConditionMenu } from '../versionReview/CurrentConditionMenu';
import { ConditionListTabs } from '../ConditionListTabs';

const MedicalConditionSidebarBase = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const conditions = useUnifiedStore(conditionsSelector);
  const selectedCondition = useUnifiedStore(selectedSimpleConditionSelector);
  const opened = useUnifiedStore(isSidebarOpenedSelector);
  const [tab, setTab] = useUnifiedTab();

  const [isConfirmMergeModalOpened, { open: openConfirmMergeModal, close: closeConfirmMergeModal }] = useDisclosure();
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [conditionIdsToMerge, setConditionIdsToMerge] = useState<string[]>([]);

  const selectedMedicalConditionId = selectedCondition?.id || conditions[0]?.id;

  const handleClickMergeButton = useCallback(() => {
    if (isMergeMode) {
      setIsMergeMode(false);
      setConditionIdsToMerge([]);

      return;
    }

    setIsMergeMode(true);
    setConditionIdsToMerge([selectedMedicalConditionId]);
  }, [isMergeMode, selectedMedicalConditionId]);

  const handleCancelMerge = useCallback(() => {
    setIsMergeMode(false);
    setConditionIdsToMerge([]);
  }, []);

  const handleConfirmMerge = useCallback(() => {
    setIsMergeMode(false);
    setConditionIdsToMerge([]);

    closeConfirmMergeModal();
  }, [closeConfirmMergeModal]);

  const handleMergeSuccess = useCallback((newMedicalConditionId: string) => {
    selectCondition(newMedicalConditionId);
    setSummaryHighlighted('');
  }, []);

  const handleSelect = useCallback(
    (medicalCondition: SimpleCondition, reviewType?: 'previous' | 'updated') => {
      if (reviewType === 'previous') {
        setPreviousConditionIdToReview(medicalCondition.id);
      } else if (reviewType === 'updated') {
        setUpdatedConditionIdToReview(medicalCondition.id);
      }

      if (isMergeMode) {
        if (conditionIdsToMerge.includes(medicalCondition.id)) {
          return setConditionIdsToMerge(conditionIdsToMerge.filter((id) => id !== medicalCondition.id));
        }

        return setConditionIdsToMerge([...conditionIdsToMerge, medicalCondition.id]);
      }

      posthog.capture('[Conditions] Select condition', {
        conditionId: medicalCondition.id,
        conditionHeader: medicalCondition.displayConditionName,
      });

      const updatedSearchParams = setTab(UnifiedTab.CONDITION_SUMMARY);

      updatedSearchParams.set('medicalConditionId', medicalCondition.id);

      updatedSearchParams.delete('query');
      updatedSearchParams.delete('fileGroup');
      updatedSearchParams.delete('fileType');

      setSearchParams(updatedSearchParams, { replace: true });

      selectCondition(medicalCondition.id);
      setSummaryHighlighted('');
      setShowSearchTermsWithNoResults(false);
      selectTermCategory(ConditionTermCategory.TERMS_FROM_CFR);
      setSelectedTerms([]);
    },
    [isMergeMode, conditionIdsToMerge, setSearchParams, setTab],
  );

  const mergeHeader = useMemo(() => {
    return (
      <Flex px={16} mb="xs" align="center">
        <Text fz="0.75rem" fw={500} color="gray.6" sx={{ whiteSpace: 'nowrap' }}>
          Select conditions to merge.
        </Text>

        <Button size="xs" variant="subtle" color="gray.7" onClick={handleCancelMerge}>
          Cancel
        </Button>

        <Button size="xs" onClick={openConfirmMergeModal}>
          Merge
        </Button>
      </Flex>
    );
  }, [handleCancelMerge, openConfirmMergeModal]);

  return (
    <Flex
      direction="column"
      mt="xs"
      sx={(theme) => ({
        transition: 'all 0.1s ease-in-out',
        borderTop: `1px solid ${theme.colors.gray[3]}`,
        borderRight: `1px solid ${theme.colors.gray[3]}`,
        background: theme.fn.lighten(theme.colors.gray[0], 0.5),
        borderTopRightRadius: 6,
        zIndex: 102,
        marginLeft: opened ? 0 : -320,
        width: 320,
        minWidth: 320,
      })}
    >
      <Flex align="center" justify="space-between" pr="xs" pos="relative">
        <Flex align="center" sx={{ width: '100%' }} pl={16} mt={11} mb="xs" gap={4}>
          <Text fw={600} color="dark.4">
            Conditions
          </Text>

          {selectedCondition && <MergeConditionsButton onClick={handleClickMergeButton} isMergeMode={isMergeMode} />}
        </Flex>

        {!isMergeMode ? (
          <Flex align="center" gap="xs" sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}>
            <ReviewNewVersionButton />

            <ConditionListTabs />
          </Flex>
        ) : null}

        <ConditionSidebarToggle />
      </Flex>

      {isMergeMode && mergeHeader}

      <Flex sx={{ height: '100%' }}>
        <CurrentConditionMenu
          selected={isMergeMode ? conditionIdsToMerge : undefined}
          onSelect={(condition) => handleSelect(condition, 'updated')}
          checkboxMode={isMergeMode}
        />
      </Flex>

      {isMergeMode && (
        <MergeConditionsModal
          opened={isConfirmMergeModalOpened}
          onClose={closeConfirmMergeModal}
          onConfirm={handleConfirmMerge}
          onSuccess={handleMergeSuccess}
          medicalConditions={conditions}
          conditionIdsToMerge={conditionIdsToMerge}
        />
      )}
    </Flex>
  );
};

export const MedicalConditionSidebar = memo(MedicalConditionSidebarBase);
