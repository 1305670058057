import { memo, useEffect, useState } from 'react';
import { Accordion, Button, Flex, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp, IconInfoTriangle } from '@tabler/icons-react';

import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { PotentialMissingFileSection } from '../../files/PotentialMissingFileSection';
import {
  DOC_HINT_TYPE_TEXT_MAPPING,
  getMedicalConditionEntryId,
  CONDITION_FIELD_TEXT_MAPPING,
} from '@/pageAI/services/medicalConditions';
import { MissingDocHint } from '@/pageAI/gql/graphql';
import { useDismissHintsForCondition } from '@/pageAI/hooks/conditions/useDismissHintsForCondition';

interface PotentialMissingFileEntryProps {
  condition: MedicalConditionItem;
  sectionField: string;
  entryKey: string;
  missingHints?: MissingDocHint[];
}

const PotentialMissingFileEntryBase = ({
  condition,
  sectionField,
  entryKey,
  missingHints,
}: PotentialMissingFileEntryProps) => {
  const [opened, { toggle, close }] = useDisclosure();
  const [width, setWidth] = useState(0);
  const { dismissHintsForCondition, isDismissing } = useDismissHintsForCondition();

  useEffect(() => {
    if (!opened) return;

    const appBodyElement = document.getElementById('app-body');
    const appBodyLeftSplitElement = appBodyElement?.querySelector('.__dbk__child-wrapper') as
      | HTMLDivElement
      | undefined;

    if (!appBodyLeftSplitElement) return;

    const observer = new ResizeObserver(() => {
      const widthString = getComputedStyle(appBodyLeftSplitElement).width;
      const width = Number(widthString.replace('px', ''));

      setWidth(width + 54);
    });

    observer.observe(appBodyLeftSplitElement);

    return () => {
      observer.disconnect();
    };
  }, [opened]);

  const handleDismissHintsForCondition = async () => {
    if (!missingHints?.length) return;

    const docHintType = missingHints[0].hintType;

    modals.openConfirmModal({
      title: `Dismiss hints`,
      children: (
        <Flex direction="column">
          <Text fz="0.875rem" color="dark.4">
            Are you sure you want to dismiss the hints for potential missing document(s) in{' '}
            {DOC_HINT_TYPE_TEXT_MAPPING[docHintType]} in&nbsp;
            <strong>{condition.displayConditionName}</strong>?
          </Text>

          <Text fz="0.875rem" color="dark.4" mt="xs">
            This field will be hidden after it is dismissed. You can display it again by deselecting the &quot;Hide N/A
            fields&quot; option.
          </Text>

          <Text fz="0.875rem" color="dark.4" mt="xs">
            You can undo this action by right-clicking the field and selecting &quot;Restore hints&quot;.
          </Text>
        </Flex>
      ),
      labels: { confirm: 'Dismiss', cancel: 'Cancel' },
      size: 480,
      cancelProps: {
        variant: 'subtle',
        color: 'gray.7',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: () =>
        dismissHintsForCondition({
          conditionId: condition.id,
          hintTypes: [docHintType],
        }),
      zIndex: 302,
      centered: true,
    });
  };

  if (!missingHints?.length) return null;

  return (
    <Flex
      direction="column"
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
        padding: '8px 16px',
      })}
    >
      <Flex>
        <Flex>
          <Text
            fz="0.875rem"
            color="dark"
            sx={{ width: 220, whiteSpace: 'pre-line', wordBreak: 'break-word', paddingRight: 16 }}
          >
            {CONDITION_FIELD_TEXT_MAPPING[sectionField as keyof typeof CONDITION_FIELD_TEXT_MAPPING]}
          </Text>
        </Flex>

        <Flex id={getMedicalConditionEntryId(condition.id, entryKey)} direction="column">
          <Flex
            align="center"
            gap={4}
            sx={(theme) => ({
              color: theme.colors.orange[7],
            })}
          >
            <IconInfoTriangle size={16} />

            <Text fz="0.875rem" sx={{ whiteSpace: 'nowrap' }} fw={500}>
              Potential missing document(s)
            </Text>

            <Button
              className="unprintable"
              size="xs"
              variant="outline"
              ml={8}
              sx={{
                height: 28,
                padding: '0 10px',
                '.ghost-Button-rightIcon': {
                  marginLeft: 4,
                },
              }}
              onClick={toggle}
              rightIcon={opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
            >
              Investigate
            </Button>

            <Button
              className="unprintable"
              ml={4}
              variant="outline"
              size="xs"
              onClick={handleDismissHintsForCondition}
              loading={isDismissing}
            >
              Dismiss
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Accordion value={opened ? 'default' : undefined}>
        <Accordion.Item
          value="default"
          sx={{
            border: 'none',
            '.ghost-Accordion-content': {
              padding: 0,
            },
          }}
        >
          <Accordion.Panel>
            <PotentialMissingFileSection
              fileIds={missingHints.map((hint) => hint.fileId) || []}
              docHintType={missingHints[0].hintType}
              width={width}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Flex>
  );
};

export const PotentialMissingFileEntry = memo(PotentialMissingFileEntryBase);
