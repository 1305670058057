import { memo } from 'react';
import { Button, Flex, Modal, TextInput } from '@mantine/core';

import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useUpdateCondition } from '@/pageAI/hooks/conditions/useUpdateCondition';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import { useForm } from '@mantine/form';

interface EditConditionModalProps {
  condition: SimpleCondition;
  opened?: boolean;
  onClose: () => void;
}

interface FormValues {
  conditionName: string;
  diagnosticCode?: string | null;
  rating?: string | null;
}

const EditConditionModalBase = ({ condition, opened = false, onClose }: EditConditionModalProps) => {
  const client = useSelectedClient(true);
  const { notify } = useNotifications();
  const { updateCondition, isUpdating } = useUpdateCondition(client.id);

  const form = useForm<FormValues>({
    initialValues: {
      conditionName: condition.displayConditionName,
      diagnosticCode: condition.diagnosticCode,
      rating: condition.rating,
    },

    validate: {
      conditionName: (value) => (!value.trim().length ? 'Condition name is required' : null),
      rating: (value) => value && (!/^\d+%/.test(value) ? 'Rating must start with a percentage' : null),
    },
  });

  const handleSubmit = async (formValues: FormValues) => {
    try {
      await updateCondition({
        id: condition.id,
        displayConditionName: formValues.conditionName,
        diagnosticCode: formValues.diagnosticCode,
        rating: formValues.rating,
      });

      notify('Success', 'Successfully edited condition', 'brand');
      onClose();
    } catch (error) {
      notify('Error', 'Failed to edit condition');
    }
  };

  return (
    <Modal title="Edit condition" opened={opened} onClose={onClose} centered>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="column" gap="xs">
          <Flex direction="column" gap={4}>
            <TextInput label="Condition name" {...form.getInputProps('conditionName')} withAsterisk />

            <TextInput label="Diagnostic code" {...form.getInputProps('diagnosticCode')} />

            <TextInput label="Rating" {...form.getInputProps('rating')} />
          </Flex>

          <Flex align="center" justify="flex-end" gap="xs">
            <Button onClick={onClose} color="gray.7" variant="subtle">
              Cancel
            </Button>

            <Button type="submit" loading={isUpdating}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export const EditConditionModal = memo(EditConditionModalBase);
