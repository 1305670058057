import { isEmpty, keys, omit } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import {
  RawMedicalConditionField,
  MedicalConditionItem,
  MedicalConditionField,
  CaseTimelineEvent,
  SimpleCondition,
} from '@/pageAI/@types/summaries';
import { truthy } from '@/shared/utils/boolean';
import { extractPercentNumber, removeEnglishStopwords, sortAlphabetically } from '@/shared/utils/string';
import { normalizeRawTimelineEvent } from '../caseTimeline';
import {
  ClaimStatus,
  ConditionStatus,
  ConditionSummaryReferencedFileInfo,
  ConditionType,
  SummariesType,
} from '@/pageAI/gql/graphql';
import { isSummaryEmpty } from '../summaries';
import { formatUSDate } from '@/shared/utils/date';
import { CONDITION_CLAIM_STATUS_ORDER_MAPPING } from './conditionClaimStatus.services';
import { safelyParseJSON } from '@/shared/utils/object';
import { ConditionFieldToProcess, ConditionSectionKey } from '@/pageAI/@types';

export enum UnifiedTab {
  CONDITION_SUMMARY = 'Condition Summary',
  TIMELINE = 'Timeline',
  CONTENT_SEARCH = 'Content Search',
  FILE_INDEX = 'File Index',
}

export const CONDITION_STATUS_COLOR_MAPPING = {
  [ConditionStatus.Ready]: 'teal',
  [ConditionStatus.Updating]: 'orange',
  [ConditionStatus.UpdateFailed]: 'red',
};

export const CONDITION_STATUS_TEXT_MAPPING = {
  [ConditionStatus.Ready]: 'Merged',
  [ConditionStatus.Updating]: 'Merging',
  [ConditionStatus.UpdateFailed]: 'Failed',
};

export const CONDITION_SECTION_TEXT_MAPPING: Record<ConditionSectionKey, string> = {
  proceduralHistory: 'Procedural History',
  filings: 'Filings',
  medicalEvidence: 'Medical Evidence',
  otherEvidence: 'Other Evidence',
};

export const CONDITION_FIELD_TEXT_MAPPING = {
  // Procedural History
  dateOfOriginalClaim: 'Original Claim',
  applicableItf: 'Applicable ITF',
  firstDecision: 'First Decision',
  otherDecisions: 'Other Decision(s)',
  vaForm218940s: 'VA Form 21-8940',

  // Filings
  initialClaims: 'Initial Claim(s)',
  supplementalClaims: 'Supplemental Claim(s)',
  requestsForHighLevelReview: 'Request(s) for High-Level Review',
  bvaDisagreementNotices: 'BVA Disagreement Notice(s)',
  disagreementNotices: 'Disagreement Notice(s)',
  vaForm21526b: 'VA Form 21-526b',
  vaForm9: 'VA Form 9 / Substantive Appeal(s)',
  socStatements: 'Statement(s) in Support of Claim',

  // Medical Evidence
  vaExaminations: 'VA Examination(s)',
  medicalOpinionsAndAddendums: 'Medical Opinion(s) and Addendum(s)',
  inServiceRecords: 'In-service Medical Record(s)',
  postServiceRecords: 'Post-service Medical Record(s)',

  // Other Evidence
  favorableFindings: 'Favorable Finding(s)',
  layStatements: 'Lay Statement(s)',
  additionalEvidence: 'Additional Evidence',

  buddyLetters: 'Buddy Letter(s)',
};

const CONDITION_FIELD_SORT_ORDER_MAPPING = {
  // Procedural History
  dateOfOriginalClaim: 1,
  applicableItf: 2,
  firstDecision: 3,
  otherDecisions: 4,
  vaForm218940s: 5,

  // Filings
  initialClaims: 1,
  supplementalClaims: 2,
  vaForm21526b: 3,
  requestsForHighLevelReview: 4,
  bvaDisagreementNotices: 5,
  disagreementNotices: 6,
  vaForm9: 7,
  socStatements: 8,

  // Medical Evidence
  vaExaminations: 1,
  medicalOpinionsAndAddendums: 2,
  inServiceRecords: 3,
  postServiceRecords: 4,

  // Other Evidence
  favorableFindings: 2,
  layStatements: 3,
  additionalEvidence: 4,
};

export const getKeywordsFromMedicalCondition = (medicalCondition: MedicalConditionItem) => {
  const keywords = new Set<string>();

  const cleanedHeaderResult = removeEnglishStopwords([medicalCondition.displayConditionName]);

  if (cleanedHeaderResult[0]) {
    keywords.add(cleanedHeaderResult[0].toLowerCase());
  }

  medicalCondition.subConditions.forEach((subCondition) => {
    const cleanedKeywordResult = removeEnglishStopwords([subCondition]);

    if (cleanedKeywordResult[0]) {
      keywords.add(cleanedKeywordResult[0].toLowerCase());
    }
  });

  return Array.from(keywords);
};

export type ConditionSortType = 'claimStatus' | 'rating' | 'asc';

export const sortConditionsAlphebetically = (medicalConditions: SimpleCondition[]) => {
  return medicalConditions.toSorted((medicalConditionA, medicalConditionB) =>
    sortAlphabetically(
      medicalConditionA.displayConditionName.toLowerCase(),
      medicalConditionB.displayConditionName.toLowerCase(),
    ),
  );
};

export const sortByRating = <T extends SimpleCondition>(conditionA: T, conditionB: T) => {
  if (conditionA.isServiceConnected && !conditionB.isServiceConnected) return -1;

  if (conditionA.rating && !conditionB.rating) return -1;

  return getConditionRatingNumber(conditionB) - getConditionRatingNumber(conditionA);
};

export const sortConditionsByRating = <T extends SimpleCondition>(conditions: T[]) => {
  return conditions.toSorted(sortByRating);
};

export const sortByClaimStatus = <T extends SimpleCondition>(conditionA: T, conditionB: T) => {
  const claimStatusAOrder = CONDITION_CLAIM_STATUS_ORDER_MAPPING[conditionA.claimStatus?.status || ClaimStatus.Unknown];
  const claimStatusBOrder = CONDITION_CLAIM_STATUS_ORDER_MAPPING[conditionB.claimStatus?.status || ClaimStatus.Unknown];

  if (claimStatusAOrder > claimStatusBOrder) return 1;

  if (claimStatusAOrder < claimStatusBOrder) return -1;

  return 0;
};

export const sortConditionsByClaimStatus = <T extends SimpleCondition>(conditions: T[]) => {
  return conditions.toSorted(sortByClaimStatus);
};

export const sortConditions = (conditions: SimpleCondition[], sortType: ConditionSortType = 'claimStatus') => {
  const legacyConditions = conditions.filter(isLegacyCondition);
  const remainingConditions = conditions
    .filter((condition) => !isLegacyCondition(condition))
    .toSorted((conditionA, conditionB) => {
      if (sortType === 'claimStatus') return sortByClaimStatus(conditionA, conditionB);

      if (sortType === 'asc')
        return sortAlphabetically(conditionA.displayConditionName, conditionB.displayConditionName);

      return sortByRating(conditionA, conditionB);
    });

  return [...remainingConditions, ...legacyConditions];
};

export const categorizeConditions = <T extends { conditionType: ConditionType }>(medicalConditions: T[]) => {
  const claimedConditions = medicalConditions.filter((condition) => condition.conditionType === ConditionType.Claimed);
  const potentialConditions = medicalConditions.filter((condition) => condition.conditionType === ConditionType.Others);

  return { claimedConditions, potentialConditions };
};

export const getFirstClaimedCondition = (conditions: SimpleCondition[]) => {
  return conditions.find((condition) => condition.conditionType === ConditionType.Claimed);
};

export const normalizeMedicalConditionTimelineEvents = (medicalCondition: MedicalConditionItem) => {
  const relatedTimelineEvents = medicalCondition.relatedTimelineEvents.map(normalizeRawTimelineEvent).filter(truthy);

  return relatedTimelineEvents;
};

export const doesMedicalConditionContainTimelineEvent = (medicalCondition: MedicalConditionItem, eventId: string) => {
  return medicalCondition.relatedTimelineEvents.some((event) => event.id === eventId);
};

export const findTimelineEventInMedicalCondition = (medicalCondition: MedicalConditionItem, eventId: string) => {
  return medicalCondition.relatedTimelineEvents.find((event) => event.id === eventId);
};

export const getMedicalConditionElementId = (medicalCondition: MedicalConditionItem) => {
  return medicalCondition.displayConditionName;
};

export const getSortedMedicalConditionFieldKeys = (medicalConditionField: MedicalConditionField) => {
  return keys(medicalConditionField).sort((keyA, keyB) => {
    const sortOrderA = CONDITION_FIELD_SORT_ORDER_MAPPING[keyA as keyof typeof CONDITION_FIELD_SORT_ORDER_MAPPING] || 0;
    const sortOrderB = CONDITION_FIELD_SORT_ORDER_MAPPING[keyB as keyof typeof CONDITION_FIELD_SORT_ORDER_MAPPING] || 0;

    return sortOrderA - sortOrderB;
  });
};

export const POTENTIAL_MISSING_DOCUMENT_SUMMARY = 'Potential missing document(s)';

export const isConditionSummaryEntryDateEmpty = (entryDate: string) => {
  return !entryDate || entryDate === 'N/A';
};

export const getConditionSummaryEntryDate = (entry: ConditionSummaryReferencedFileInfo) => {
  const dateString = isConditionSummaryEntryDateEmpty(entry.eventDate) ? entry.fileReceiveDate : entry.eventDate;

  return dateString;
};

export const getFormattedConditionSummaryEntryDate = (entry: ConditionSummaryReferencedFileInfo) => {
  return formatUSDate(getConditionSummaryEntryDate(entry));
};

export const ensureBackwardCompatibleConditionSummary = (summary: string) => {
  // Check if the prefix of the summary item contains a date of the form mm/dd/yyyy (e.g., 8/18/1984, 8/1/20114, etc.),
  // and remove the date if it is at the beginning of the string. Then, strip the following leading chars: spaces, -, and :.
  const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}/;
  const dateMatch = summary.match(dateRegex);

  if (!dateMatch) return summary;

  const date = dateMatch[0];

  return summary.slice(date.length).replace(/^[-: ]+/, '');
};

export const normalizeMedicalConditionField = (
  condition: MedicalConditionItem,
  medicalConditionField: RawMedicalConditionField | null | undefined,
  showNullFields: boolean,
) => {
  if (!medicalConditionField) return undefined;

  const normalizedMedicalConditionField: MedicalConditionField = {};

  keys(medicalConditionField).forEach((sectionField) => {
    if (sectionField === '__typename') return;

    const medicalConditionFieldItem = medicalConditionField[sectionField];

    normalizedMedicalConditionField[sectionField] = medicalConditionFieldItem?.data
      ? [medicalConditionFieldItem.data]
          .flat()
          .toSorted((itemA, itemB) =>
            sortAlphabetically(getConditionSummaryEntryDate(itemA), getConditionSummaryEntryDate(itemB)),
          )
      : [];

    normalizedMedicalConditionField[sectionField] = normalizedMedicalConditionField[sectionField].filter(
      (field) => !field.isDuplicated,
    );

    normalizedMedicalConditionField[sectionField] = normalizedMedicalConditionField[sectionField].map((field) => ({
      ...field,
      summary: ensureBackwardCompatibleConditionSummary(field.summary),
    }));

    if (!showNullFields) {
      normalizedMedicalConditionField[sectionField] = normalizedMedicalConditionField[sectionField].filter(
        (field) => field.summary !== 'N/A',
      );

      if (
        normalizedMedicalConditionField[sectionField].length === 0 &&
        !medicalConditionFieldItem?.missingDocHints?.length
      ) {
        delete normalizedMedicalConditionField[sectionField];
      }
    }
  });

  if (isEmpty(normalizedMedicalConditionField)) return undefined;

  return normalizedMedicalConditionField;
};

export const getConditionEntryKey = (sectionKey: string, sectionField: string, entryIndex: number) =>
  [sectionKey, sectionField, entryIndex].join('.');

export const convertConditionToTimelineEvents = (
  condition: ReturnType<typeof normalizeMedicalConditionFields>,
  sectionsToInclude?: ConditionSectionKey[],
) => {
  const timelineEvents: (CaseTimelineEvent | null)[] = [];

  let fieldsToProcess: ConditionFieldToProcess[] = [
    'proceduralHistoryEntries',
    'filingsEntries',
    'medicalEvidenceEntries',
    'otherEvidenceEntries',
  ];

  if (Array.isArray(sectionsToInclude)) {
    if (!sectionsToInclude.includes('proceduralHistory'))
      fieldsToProcess = fieldsToProcess.filter(
        (field) => !['proceduralHistoryEntries', 'filingEntries'].includes(field),
      );

    if (!sectionsToInclude.includes('medicalEvidence'))
      fieldsToProcess = fieldsToProcess.filter((field) => field !== 'medicalEvidenceEntries');

    if (!sectionsToInclude.includes('otherEvidence'))
      fieldsToProcess = fieldsToProcess.filter((field) => field !== 'otherEvidenceEntries');
  }

  fieldsToProcess.forEach((sectionKey) => {
    const conditionField = condition[sectionKey];

    if (!conditionField) return;

    keys(conditionField).forEach((sectionField) => {
      const entries = conditionField[sectionField];

      entries.forEach((entry, entryIndex) => {
        timelineEvents.push(
          normalizeRawTimelineEvent({
            id: uuidv4(),
            date: getConditionSummaryEntryDate(entry),
            reference: entry.reference,
            summaries: [
              [
                CONDITION_FIELD_TEXT_MAPPING[sectionField as keyof typeof CONDITION_FIELD_TEXT_MAPPING] || sectionField,
                isSummaryEmpty(entry.summary) ? null : ensureBackwardCompatibleConditionSummary(entry.summary),
              ]
                .filter(truthy)
                .join(': '),
            ],
            summariesTypes: [SummariesType.EventSummary, SummariesType.EventSummary],
            details: [],
            detailsTypes: [],
            metadata: [
              { key: 'fileId', value: entry.fileId || entry.reference },
              {
                key: 'sectionField',
                value: sectionField,
              },
              { key: 'entry', value: JSON.stringify(entry) },
              { key: 'condition', value: JSON.stringify(condition) },
              { key: 'entryKey', value: getConditionEntryKey(sectionKey, sectionField, entryIndex) },
            ],
            bookmarked: false,
            duplicated: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            viewerCanBookmark: false,
            viewerCanComment: false,
            viewerCanDelete: false,
            viewerCanHide: false,
            viewerCanUnbookmark: false,
            viewerCanUnhide: false,
            viewerCanUpdate: false,
          }),
        );
      });
    });
  });

  const nonNullTimelineEvents = timelineEvents.filter(truthy);

  const filteredTimelineEvents = nonNullTimelineEvents.filter((event, index) => {
    const { summaries, date } = event;
    const previousEvents = nonNullTimelineEvents.slice(0, index);

    return !previousEvents.some((prevEvent) => {
      return prevEvent.summaries?.join('') === summaries?.join('') && prevEvent.date === date;
    });
  });

  return filteredTimelineEvents;
};

export const normalizeSimpleCondition = <T extends SimpleCondition>(condition: T): T => {
  // This is a debug flag that is not used by our users but is useful for debugging by developers
  const searchParams = new URL(window.location.href).searchParams;
  const isDebug = searchParams.get('debug') === 'true';

  return {
    ...condition,
    displayConditionName: isDebug
      ? condition.displayConditionName
      : removeConditionDebugWords(condition.displayConditionName),
    headerCondition: isDebug ? condition.headerCondition : removeConditionDebugWords(condition.displayConditionName),
    subConditions: isDebug ? condition.subConditions : condition.subConditions.map(removeConditionDebugWords),
  };
};

export const normalizeMedicalCondition = (medicalConditionItem: MedicalConditionItem): MedicalConditionItem => {
  // This is a debug flag that is not used by our users but is useful for debugging by developers
  const searchParams = new URL(window.location.href).searchParams;
  const isDebug = searchParams.get('debug') === 'true';

  return {
    ...medicalConditionItem,
    ...normalizeSimpleCondition(medicalConditionItem),
    mergedConditions: isDebug
      ? medicalConditionItem.mergedConditions
      : medicalConditionItem.mergedConditions?.map(removeConditionDebugWords),
  };
};

export const normalizeMedicalConditionFields = (condition: MedicalConditionItem, showNullFields: boolean) => {
  return {
    ...condition,
    proceduralHistoryEntries: normalizeMedicalConditionField(
      condition,
      omit(condition.proceduralHistory, 'filings'),
      showNullFields,
    ),
    filingsEntries: normalizeMedicalConditionField(condition, condition.proceduralHistory?.filings, showNullFields),
    medicalEvidenceEntries: normalizeMedicalConditionField(condition, condition.medicalEvidence, showNullFields),
    otherEvidenceEntries: normalizeMedicalConditionField(condition, condition.otherEvidence, showNullFields),
  };
};

export const isCombinedRatingEmpty = (combinedRating?: string | null) => {
  return !combinedRating || combinedRating === 'N/A';
};

export const removeConditionDebugWords = (text: string) => {
  const debugWords = ['(seed-header)', '(seed-main)', '(seed-sub)', '(header)', '(main)', '(sub)'];

  debugWords.forEach((debugWord) => (text = text.replaceAll(debugWord, '')));

  return text.trim();
};

export const shouldAlwaysHideNullFields = (condition: SimpleCondition) => {
  return [ConditionType.Va_21_526, ConditionType.Others].includes(condition.conditionType);
};

export const getConditionName = (medicalCondition: SimpleCondition) => {
  return removeConditionDebugWords(medicalCondition.displayConditionName);
};

export const getMedicalConditionParamsFromSearchParams = (searchParams: URLSearchParams) => {
  const medicalConditionId = searchParams.get('medicalConditionId');
  const tab = searchParams.get('tab') as UnifiedTab | null;
  const entryKey = searchParams.get('entryKey');

  return { medicalConditionId, tab, entryKey };
};

export const isConditionMerging = (medicalCondition: SimpleCondition) => {
  return medicalCondition.conditionStatus === ConditionStatus.Updating;
};

export const doesConditionHaveEvent = (medicalCondition: MedicalConditionItem, eventId: string) => {
  return medicalCondition.relatedTimelineEvents.some((event) => event.id === eventId);
};

export const doesConditionHaveFile = (medicalCondition: MedicalConditionItem, fileId: string) => {
  const normalizedCondition = normalizeMedicalConditionFields(medicalCondition, false);

  return (
    ['proceduralHistoryEntries', 'filingsEntries', 'medicalEvidenceEntries', 'otherEvidenceEntries'] as const
  ).some((sectionKey) => {
    const sectionValue = normalizedCondition[sectionKey];

    if (!sectionValue) return false;

    return keys(sectionValue).some((fieldKey) => {
      const field = sectionValue[fieldKey];

      return field.some((entry) => entry.fileId === fileId);
    });
  });
};

export const canDisplayCodesheetSection = (condition: MedicalConditionItem) => {
  return condition.allCodesheets?.data?.length || !isSummaryEmpty(condition.latestCodesheet?.data?.summary);
};

export const getConditionRatingNumber = (condition: SimpleCondition) => {
  return Number(extractPercentNumber(condition.rating || '0%'));
};

interface DedupedConditionSummaryEntry extends ConditionSummaryReferencedFileInfo {
  subEntries: ConditionSummaryReferencedFileInfo[];
}

export const dedupeConditionSummaryEntries = (entries: ConditionSummaryReferencedFileInfo[]) => {
  const deduped: DedupedConditionSummaryEntry[] = [];

  entries.forEach((entry) => {
    const sameDateEntry = deduped.find(
      (anotherEntry) => getConditionSummaryEntryDate(entry) === getConditionSummaryEntryDate(anotherEntry),
    );

    if (sameDateEntry) {
      sameDateEntry.subEntries.push(entry);

      return;
    }

    deduped.push({ ...entry, subEntries: [] });
  });

  return deduped;
};

export const isLegacyCondition = (condition: SimpleCondition) => {
  return ['Special Monthly Compensation', 'Individual Unemployability', 'Cause of Death'].includes(
    condition.displayConditionName,
  );
};

export const getConditionChronologicalEventMetadata = (event: CaseTimelineEvent) => {
  const metadata = event.metadata || [];
  const metadataMap: Record<string, string> = {};

  metadata.forEach(({ key, value }) => {
    metadataMap[key] = value;
  });

  const { fileId, sectionField, entry, condition, entryKey } = metadataMap;

  return {
    fileId,
    sectionField,
    entry: entry ? (safelyParseJSON(entry) as ConditionSummaryReferencedFileInfo) : undefined,
    condition: condition ? (safelyParseJSON(condition) as MedicalConditionItem) : undefined,
    entryKey,
  };
};

export const isArchivedCondition = (condition: SimpleCondition) => {
  return condition.hidden;
};

export const getRatingInfo = (condition: SimpleCondition) => {
  // The info will be extracted from condition.rating in this format: 10% from 10/10/2021
  const [percentPart, datePart] = condition.rating?.split('from') || [];

  return {
    isServiceConnected: condition.isServiceConnected,
    ratingPercent: percentPart ? extractPercentNumber(percentPart) : null,
    effectiveDate: datePart ? datePart.trim() : null,
  };
};

// ==================== ELEMENTS ====================
export const getMedicalConditionEntryId = (medicalConditionId: string, entryKey: string) =>
  [medicalConditionId, entryKey].join('.');
