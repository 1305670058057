import { memo, useMemo, useRef } from 'react';
import { Chip, Flex } from '@mantine/core';

import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useTermSearchResultsState } from '@/pageAI/services/conditionSearchTerms';
import { posthog } from '@/shared/plugins/posthog';
import { showSearchTermsWithNoResultsSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { SearchRelevanceIndicator } from '../../search/SearchRelevanceIndicator';

interface ConditionSearchTermChipsProps {
  terms: string[];
  value?: string | string[];
  multiple?: boolean;
  onSelect?: (searchTerm: string) => void;
  onSelectMultiple?: (searchTerms: string[]) => void;
}

const ConditionSearchTermChipsBase = ({
  terms,
  value,
  multiple = false,
  onSelect,
  onSelectMultiple,
}: ConditionSearchTermChipsProps) => {
  const client = useSelectedClient(true);
  const scoreResultByTerm = useTermSearchResultsState((state) => state.scoreByTerm);
  const showTermsWithNoResults = useUnifiedStore(showSearchTermsWithNoResultsSelector);
  const isHoldingCtrl = useRef(false);

  const filteredTerms = useMemo(
    () =>
      terms
        .filter((term) => showTermsWithNoResults || scoreResultByTerm[term]?.score)
        .toSorted((a, b) => {
          const aResult = scoreResultByTerm[a];
          const bResult = scoreResultByTerm[b];

          return (bResult?.score || 0) - (aResult?.score || 0);
        }),
    [terms, showTermsWithNoResults, scoreResultByTerm],
  );

  const handleChange = (searchTerm: string | string[]) => {
    const selectedSimpleCondition = useUnifiedStore.getState().selectedSimpleCondition;

    posthog.capture('[Search] Select condition search term', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      conditionName: selectedSimpleCondition?.displayConditionName,
      searchTerm: JSON.stringify(searchTerm),
    });

    if (Array.isArray(searchTerm)) {
      if (!isHoldingCtrl.current && Array.isArray(value) && value.length <= 1) {
        const lastSearchTerm = searchTerm.at(-1);

        if (lastSearchTerm) {
          return onSelectMultiple?.([lastSearchTerm]);
        }
      }

      onSelectMultiple?.(searchTerm);

      return;
    }

    onSelect?.(searchTerm);
  };

  const handleDetectCtrlKey = (event: React.MouseEvent) => {
    isHoldingCtrl.current = event.metaKey || event.ctrlKey;
  };

  return (
    <>
      <Chip.Group value={value} multiple={multiple} onChange={handleChange}>
        {filteredTerms.map((searchTerm) => {
          const scoreResult = scoreResultByTerm[searchTerm];
          const score = scoreResult?.score || 0;

          return (
            <Chip
              key={searchTerm}
              value={searchTerm}
              size="xs"
              variant="filled"
              sx={{
                '.ghost-Chip-iconWrapper': {
                  display: 'none',
                },
                '.ghost-Chip-label': {
                  paddingLeft: 12,
                  '.relevance-indicator': {
                    marginRight: -4,
                  },
                  '&[data-checked=true]': {
                    paddingLeft: 12,
                    '.relevance-indicator': {
                      marginRight: 4,
                    },
                  },
                },
              }}
            >
              <Flex align="center" gap={4} onMouseDown={handleDetectCtrlKey} onMouseUp={handleDetectCtrlKey}>
                {searchTerm}

                <SearchRelevanceIndicator relevanceScore={score} />
              </Flex>
            </Chip>
          );
        })}
      </Chip.Group>
    </>
  );
};

export const ConditionSearchTermChips = memo(ConditionSearchTermChipsBase);
