import { memo, useState } from 'react';

import { SimpleCondition } from '@/pageAI/@types/summaries';
import { ActionIcon, Menu } from '@mantine/core';
import { IconArchive, IconDotsVertical, IconPencil } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { EditConditionModal } from '../EditConditionModal';
import { ArchiveConditionModal } from '../ArchiveConditionModal';
import { isArchivedCondition } from '@/pageAI/services/medicalConditions';

interface ConditionActionMenuProps {
  condition: SimpleCondition;
}

const ConditionActionMenuBase = ({ condition }: ConditionActionMenuProps) => {
  const [isEditingModalOpened, { close: closeEditingModal, open: openEditingModal }] = useDisclosure();
  const [isArchivingModalOpened, { close: closeArchivingModal, open: openArchivingModal }] = useDisclosure();
  const [shouldUnarchive, setShouldUnarchive] = useState(false);

  return (
    <>
      <Menu shadow="sm" withinPortal>
        <Menu.Target>
          <ActionIcon size="xs" mr={-8} className="actions">
            <IconDotsVertical size={14} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item icon={<IconPencil size={14} />} onClick={openEditingModal}>
            Edit
          </Menu.Item>

          {isArchivedCondition(condition) ? (
            <Menu.Item
              color="red"
              icon={<IconArchive size={14} />}
              onClick={() => {
                setShouldUnarchive(true);
                openArchivingModal();
              }}
            >
              Unarchive
            </Menu.Item>
          ) : (
            <Menu.Item
              color="red"
              icon={<IconArchive size={14} />}
              onClick={() => {
                setShouldUnarchive(false);
                openArchivingModal();
              }}
            >
              Archive
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>

      <EditConditionModal condition={condition} opened={isEditingModalOpened} onClose={closeEditingModal} />

      <ArchiveConditionModal
        condition={condition}
        opened={isArchivingModalOpened}
        onClose={closeArchivingModal}
        shouldUnarchive={shouldUnarchive}
      />
    </>
  );
};

export const ConditionActionMenu = memo(ConditionActionMenuBase);
