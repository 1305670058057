import { memo, useCallback, useMemo } from 'react';
import { ActionIcon, ActionIconProps, Tooltip } from '@mantine/core';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';

import {
  isSidebarOpenedSelector,
  selectedSimpleConditionSelector,
  toggleConditionSidebar,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { posthog } from '@/shared/plugins/posthog';

interface ConditionSidebarToggleProps {}

const ConditionSidebarToggleBase = (props: ConditionSidebarToggleProps) => {
  const opened = useUnifiedStore(isSidebarOpenedSelector);
  const selectedSimpleCondition = useUnifiedStore(selectedSimpleConditionSelector);

  const sharedProps: ActionIconProps = useMemo(
    () => ({
      size: 'xs',
      pos: 'absolute',
      top: 10,
      variant: 'outline',
      color: 'gray.6',
      sx: (theme) => ({
        background: theme.colors.gray[0],
        '&:hover': {
          background: theme.colors.gray[1],
        },
      }),
    }),
    [],
  );

  const handleClick = useCallback(() => {
    posthog.capture('[Conditions] Toggle condition sidebar', {
      conditionId: selectedSimpleCondition?.id,
      conditionName: selectedSimpleCondition?.displayConditionName,
      newOpenedState: !opened,
    });

    toggleConditionSidebar(!opened);
  }, [selectedSimpleCondition, opened]);

  if (!selectedSimpleCondition) return null;

  if (opened) {
    return (
      <Tooltip key={String(opened)} label="Collapse" withArrow withinPortal>
        <ActionIcon onClick={handleClick} right={-10} {...sharedProps}>
          <IconChevronsLeft size={16} />
        </ActionIcon>
      </Tooltip>
    );
  }

  return (
    <Tooltip key={String(opened)} label="Open condition sidebar" withArrow withinPortal>
      <ActionIcon onClick={handleClick} right={-16} {...sharedProps}>
        <IconChevronsRight size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export const ConditionSidebarToggle = memo(ConditionSidebarToggleBase);
