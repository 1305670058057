import { memo, useMemo } from 'react';
import { Button, Checkbox, Flex, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';

import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useUpdateCondition } from '@/pageAI/hooks/conditions/useUpdateCondition';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import { getRatingInfo } from '@/pageAI/services/medicalConditions';
import { formatUSDate, parseDateOnly } from '@/shared/utils/date';

interface EditConditionModalProps {
  condition: SimpleCondition;
  opened?: boolean;
  onClose: () => void;
}

interface FormValues {
  conditionName: string;
  diagnosticCode?: string | null;
  isServiceConnected: boolean;
  ratingPercent?: string | null;
  effectiveDate?: string | null;
}

const EditConditionModalBase = ({ condition, opened = false, onClose }: EditConditionModalProps) => {
  const client = useSelectedClient(true);
  const { notify } = useNotifications();
  const { updateCondition, isUpdating } = useUpdateCondition(client.id);

  const ratingInfo = useMemo(() => getRatingInfo(condition), [condition]);

  const form = useForm<FormValues>({
    initialValues: {
      conditionName: condition.displayConditionName,
      diagnosticCode: condition.diagnosticCode,
      isServiceConnected: condition.isServiceConnected,
      ratingPercent: ratingInfo.ratingPercent,
      effectiveDate: ratingInfo.effectiveDate,
    },

    validate: {
      conditionName: (value) => (!value.trim().length ? 'Condition name is required' : null),
      ratingPercent: (value) => {
        if (!value) return null;

        const parsedValue = Number(value);

        if (parsedValue < 0 || parsedValue > 100) {
          return 'Rating must be between 0% and 100%';
        }

        return null;
      },
    },
  });

  const handleSubmit = async (formValues: FormValues) => {
    try {
      await updateCondition({
        id: condition.id,
        displayConditionName: formValues.conditionName,
        diagnosticCode: formValues.diagnosticCode,
        isServiceConnected: formValues.isServiceConnected,
        ...(formValues.isServiceConnected
          ? {
              rating: null,
            }
          : {
              rating: null,
            }),
      });

      notify('Success', 'Successfully edited condition', 'brand');
      onClose();
    } catch (error) {
      notify('Error', 'Failed to edit condition');
    }
  };

  const handleRatingChange = (value: string) => {
    // Only allow numbers and empty string
    if (!/^\d*$/.test(value)) return;

    form.setFieldValue('ratingPercent', value);
  };

  const handleEffectiveDateChange = (value: Date | string | null) => {
    form.setFieldValue('effectiveDate', value === 'string' ? value : formatUSDate(value || new Date()));
  };

  return (
    <Modal keepMounted={false} title="Edit condition" opened={opened} onClose={onClose} centered>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="column" gap="xs">
          <Flex direction="column" gap={4}>
            <TextInput label="Condition name" {...form.getInputProps('conditionName')} withAsterisk />

            <TextInput label="Diagnostic code" {...form.getInputProps('diagnosticCode')} />

            <Checkbox
              mt={4}
              label="Service connected"
              {...form.getInputProps('isServiceConnected', { type: 'checkbox' })}
            />

            {form.values.isServiceConnected && (
              <>
                <TextInput
                  label="Rating %"
                  {...form.getInputProps('ratingPercent')}
                  value={form.values.ratingPercent || ''}
                  onChange={(event) => handleRatingChange(event?.target.value)}
                  withAsterisk
                />

                <DatePickerInput
                  label="Effective date"
                  value={parseDateOnly(form.values.effectiveDate || new Date())}
                  valueFormat="MM/DD/YY"
                  onChange={handleEffectiveDateChange}
                  withAsterisk
                />
              </>
            )}
          </Flex>

          <Flex align="center" justify="flex-end" gap="xs">
            <Button onClick={onClose} color="gray.7" variant="subtle">
              Cancel
            </Button>

            <Button type="submit" loading={isUpdating}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export const EditConditionModal = memo(EditConditionModalBase);
