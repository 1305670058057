import { memo, useMemo } from 'react';
import { SegmentedControl } from '@mantine/core';

import {
  ConditionListTab,
  conditionListTabSelector,
  conditionsSelector,
  setConditionListTab,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { isArchivedCondition } from '@/pageAI/services/medicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useIncrementalSummaryFeatureFlag } from '@/pageAI/hooks/featureFlags/useIncrementalSummaryFeatureFlag';
import { useConditionSummaryDeltas } from '@/pageAI/hooks/conditions/useClientMedicalConditions';
import { shouldReviewIncrementalChanges } from '@/pageAI/services/medicalConditions/conditionVersionReview.services';

interface ConditionListTabsProps {}

const ConditionListTabsBase = (props: ConditionListTabsProps) => {
  const client = useSelectedClient(true);
  const { enabled } = useIncrementalSummaryFeatureFlag();
  const { conditionSummaryDeltas } = useConditionSummaryDeltas({ clientId: client.id });

  const conditions = useUnifiedStore(conditionsSelector);
  const tab = useUnifiedStore(conditionListTabSelector);
  const archivedConditions = useMemo(() => conditions.filter(isArchivedCondition), [conditions]);

  const shouldReview = useMemo(() => shouldReviewIncrementalChanges(conditionSummaryDeltas), [conditionSummaryDeltas]);

  if (!archivedConditions.length || (enabled && shouldReview)) return null;

  return (
    <SegmentedControl
      mr={4}
      size="xs"
      value={tab}
      data={['All', 'Archived']}
      onChange={(value) => setConditionListTab(value as ConditionListTab)}
      sx={{
        '.ghost-SegmentedControl-label': {
          width: 64,
        },
      }}
    />
  );
};

export const ConditionListTabs = memo(ConditionListTabsBase);
