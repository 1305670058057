import { UnifiedState } from './unified.states';

export const conditionsSelector = (state: UnifiedState) => state.conditions;

export const selectedSimpleConditionSelector = (state: UnifiedState) => state.selectedSimpleCondition;

export const selectedConditionSelector = (state: UnifiedState) => state.selectedCondition;

export const isSidebarOpenedSelector = (state: UnifiedState) => state.isSidebarOpened;

// ============================== Summary Tab ==============================
export const conditionListTabSelector = (state: UnifiedState) => state.summaryTabState.conditionListTab;

export const summaryTabViewModeSelector = (state: UnifiedState) => state.summaryTabState.viewMode;

export const showNullFieldsSelector = (state: UnifiedState) => state.summaryTabState.showNullFields;

export const summaryTabSortOrderSelector = (state: UnifiedState) => state.summaryTabState.sortOrder;

export const summaryTabHighlightedSelector = (state: UnifiedState) => state.summaryTabState.highlighted;

export const isPrintingConditionSummarySelector = (state: UnifiedState) => state.summaryTabState.isPrinting;

export const isReviewingIncrementalChangesSelector = (state: UnifiedState) =>
  state.summaryTabState.isReviewingIncrementalChanges;

export const previousConditionIdToReviewSelector = (state: UnifiedState) =>
  state.summaryTabState.previousConditionIdToReview;

export const updatedConditionIdToReviewSelector = (state: UnifiedState) =>
  state.summaryTabState.updatedConditionIdToReview;

export const previousConditionsSelector = (state: UnifiedState) => state.summaryTabState.previousConditions;

export const onlyReviewNewIncrementalChangesSelector = (state: UnifiedState) =>
  state.summaryTabState.onlyReviewNewChanges;

// =============================== Content Search ===============================
export const contentSearchStateSelector = (state: UnifiedState) => state.contentSearchState;

export const showMedicalTermSearchSelector = (state: UnifiedState) => state.contentSearchState.showMedicalTermSearch;

export const selectedTermsSelector = (state: UnifiedState) => state.contentSearchState.selectedTerms;

export const showSearchTermsWithNoResultsSelector = (state: UnifiedState) =>
  state.contentSearchState.showSearchTermsWithNoResults;

export const selectedTermCategorySelector = (state: UnifiedState) => state.contentSearchState.selectedTermCategory;
