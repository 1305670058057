import { Fragment, memo, useMemo, useState } from 'react';
import { ActionIcon, Box, Checkbox, Divider, Flex, Input, Loader, ScrollArea, Text, Tooltip } from '@mantine/core';
import {
  IconSearch,
  IconSortAscendingLetters,
  IconSortDescending2,
  IconSortDescendingNumbers,
} from '@tabler/icons-react';

import { categorizeConditions, ConditionSortType, sortConditions } from '@/pageAI/services/medicalConditions';
import { MedicalConditionMenuProps } from './MedicalConditionMenu.types';
import { MedicalConditionMenuItem } from '../MedicalConditionMenuItem';
import { posthog } from '@/shared/plugins/posthog';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import { getNextItemInArray } from '@/shared/utils/array';
import { useConditionClaimStatusFeatureFlag } from '@/pageAI/hooks/featureFlags/useConditionClaimStatusFeatureFlag';
import { truthy } from '@/shared/utils/boolean';

const SORT_BUTTON_TOOLTIP_BY_SORT_TYPE: Record<ConditionSortType, string> = {
  claimStatus: 'Sort by claim status',
  rating: 'Sort by rating',
  asc: 'Sort alphabetically',
};

const SORT_BUTTON_ICON_BY_SORT_TYPE: Record<ConditionSortType, JSX.Element> = {
  claimStatus: <IconSortDescending2 size={16} />,
  rating: <IconSortDescendingNumbers size={16} />,
  asc: <IconSortAscendingLetters size={16} />,
};

const MedicalConditionMenuBase = ({
  medicalConditions,
  selected,
  highlighted,
  bolded,
  newlyAdded,
  onSelect,
  onSelectMultiple,
  maxHeight = 'calc(100vh - 154px)',
  checkboxMode = false,
  loading = false,
  searchable = true,
  grouped = true,
  sortable = true,
}: MedicalConditionMenuProps) => {
  const { enabled } = useConditionClaimStatusFeatureFlag();

  const [searchValue, setSearchValue] = useState('');
  const [sortType, setSortType] = useState<ConditionSortType>(enabled ? 'claimStatus' : 'rating');
  const [selectAllClaimedConditions, setSelectAllClaimedConditions] = useState(true);
  const [selectAllOtherConditions, setSelectAllPotentialConditions] = useState(true);

  const filteredMedicalConditions = useMemo(
    () =>
      medicalConditions.filter((medicalCondition) =>
        medicalCondition.displayConditionName.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [medicalConditions, searchValue],
  );

  const { claimedConditions, potentialConditions } = useMemo(
    () => categorizeConditions(filteredMedicalConditions),
    [filteredMedicalConditions],
  );

  const sortedClaimedConditions = useMemo(
    () => sortConditions(claimedConditions, sortType),
    [claimedConditions, sortType],
  );

  const handleToggleSelectAllClaimedConditions = () => {
    if (!selectAllClaimedConditions) {
      onSelectMultiple?.(claimedConditions);

      setSelectAllClaimedConditions(true);

      return;
    }

    onSelectMultiple?.(claimedConditions, true);

    setSelectAllClaimedConditions(false);
  };

  const handleToggleSelectAllOtherConditions = () => {
    if (!selectAllOtherConditions) {
      onSelectMultiple?.(potentialConditions);

      setSelectAllPotentialConditions(true);

      return;
    }

    onSelectMultiple?.(potentialConditions, true);

    setSelectAllPotentialConditions(false);
  };

  const renderSortButton = () => {
    if (!sortable) return null;

    return (
      <Tooltip label={SORT_BUTTON_TOOLTIP_BY_SORT_TYPE[sortType]} withArrow withinPortal>
        <ActionIcon
          size="xs"
          onClick={() =>
            setSortType(
              getNextItemInArray(
                [enabled ? 'claimStatus' : '', 'rating', 'asc'].filter(truthy) as ConditionSortType[],
                sortType,
              ),
            )
          }
          sx={(theme) => ({
            '&:hover': {
              background: theme.colors.gray[2],
            },
          })}
        >
          {SORT_BUTTON_ICON_BY_SORT_TYPE[sortType]}
        </ActionIcon>
      </Tooltip>
    );
  };

  const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setSearchValue(newValue);

    posthog.capture('[Conditions] Search for conditions', {
      searchValue: newValue,
    });
  };

  const renderOptions = (medicalConditions: SimpleCondition[], hideStatusIndicator = false) => {
    return (
      <>
        {medicalConditions.map((medicalCondition) => {
          return (
            <MedicalConditionMenuItem
              key={medicalCondition.id}
              medicalCondition={medicalCondition}
              selected={selected?.includes(medicalCondition.id)}
              highlighted={highlighted?.includes(medicalCondition.id)}
              bolded={bolded?.includes(medicalCondition.id)}
              newlyAdded={newlyAdded?.includes(medicalCondition.id)}
              highlightKeywords={[searchValue]}
              onSelect={onSelect}
              checkboxMode={checkboxMode}
              hideStatusIndicator={hideStatusIndicator}
            />
          );
        })}
      </>
    );
  };

  const renderSearchInput = () => {
    if (!searchable) return null;

    return (
      <Flex
        pl={4}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <Input
          type="text"
          placeholder="Search conditions..."
          value={searchValue}
          onChange={handleChangeSearchValue}
          icon={<IconSearch size={16} />}
          sx={(theme) => ({
            width: '100%',
            padding: 0,
            input: {
              fontSize: '0.875rem',
              border: 'none',
              background: theme.fn.lighten(theme.colors.gray[0], 0.5),
            },
          })}
        />
      </Flex>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Flex align="center" justify="center" sx={{ height: 40 }} gap={6}>
          <Loader size={14} />

          <Text fz="0.875rem" color="gray.7">
            Loading conditions...
          </Text>
        </Flex>
      );
    }

    if (filteredMedicalConditions.length === 0) {
      return (
        <Flex align="center" justify="center" sx={{ height: 40 }}>
          <Text fz="0.875rem" color="gray.7">
            Nothing found
          </Text>
        </Flex>
      );
    }

    if (!grouped) {
      return <Box>{renderOptions(filteredMedicalConditions, true)}</Box>;
    }

    return (
      <>
        {claimedConditions.length > 0 && (
          <>
            <Flex align="center" justify="space-between" gap="xs" px={12} py={6}>
              <Flex align="center" gap={4}>
                <Text fz="0.75rem" color="gray.6" fw={500}>
                  Claimed Conditions
                </Text>

                {renderSortButton()}
              </Flex>

              {onSelectMultiple && (
                <Checkbox
                  checked={selectAllClaimedConditions}
                  onChange={handleToggleSelectAllClaimedConditions}
                  size="xs"
                  label="Select all"
                />
              )}
            </Flex>

            <Box
              sx={(theme) => ({
                '.legacy-condition': {
                  '&:not(.legacy-condition ~ .legacy-condition)': {
                    marginTop: 4,
                    '.prefix': {
                      marginBottom: 4,
                      marginLeft: 12,
                      width: '50%',
                      height: 1,
                      background: theme.colors.gray[3],
                    },
                  },
                },
              })}
            >
              {renderOptions(sortedClaimedConditions)}
            </Box>
          </>
        )}

        {potentialConditions.length > 0 && (
          <>
            {claimedConditions.length > 0 && <Divider my={4} color="gray.3" />}

            <Flex align="center" justify="space-between" gap="xs" px={12} py={6}>
              <Text fz="0.75rem" color="gray.6" fw={500}>
                Potential Conditions
              </Text>

              {onSelectMultiple && (
                <Checkbox
                  checked={selectAllOtherConditions}
                  onChange={handleToggleSelectAllOtherConditions}
                  size="xs"
                  label="Select all"
                />
              )}
            </Flex>

            {renderOptions(potentialConditions, true)}
          </>
        )}
      </>
    );
  };

  return (
    <Flex direction="column" onClick={(event) => event.stopPropagation()} sx={{ width: '100%' }}>
      {renderSearchInput()}

      {maxHeight ? (
        <ScrollArea.Autosize
          sx={{
            maxHeight,
            '> div': {
              width: '100%',
            },
          }}
        >
          <Box pl={4} py={4} pr="sm" sx={{ width: '100%' }}>
            {renderContent()}
          </Box>
        </ScrollArea.Autosize>
      ) : (
        <Box pl={4} py={4} pr="sm" sx={{ width: '100%' }}>
          {renderContent()}
        </Box>
      )}
    </Flex>
  );
};

export const MedicalConditionMenu = memo(MedicalConditionMenuBase);
