import { memo } from 'react';
import { Button, Flex, Modal, Text } from '@mantine/core';

import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useUpdateCondition } from '@/pageAI/hooks/conditions/useUpdateCondition';
import { SimpleCondition } from '@/pageAI/@types/summaries';

interface EditConditionModalProps {
  condition: SimpleCondition;
  opened?: boolean;
  shouldUnarchive?: boolean;
  onClose: () => void;
}

const ArchiveConditionModalBase = ({
  condition,
  opened = false,
  shouldUnarchive = false,
  onClose,
}: EditConditionModalProps) => {
  const client = useSelectedClient(true);
  const { notify } = useNotifications();
  const { updateCondition, isUpdating } = useUpdateCondition(client.id);

  const handleArchiveOrUnarchive = async () => {
    try {
      await updateCondition({ id: condition.id, hidden: !shouldUnarchive });

      onClose();
      notify(
        'Success',
        shouldUnarchive ? 'Successfully unarchived condition' : 'Successfully archived condition',
        'brand',
      );
    } catch (error) {
      console.error(error);

      notify('Error', shouldUnarchive ? 'Failed to unarchive condition' : 'Failed to archive condition');
    }
  };

  return (
    <Modal
      keepMounted={false}
      title={shouldUnarchive ? 'Unarchive condition' : 'Archive condition'}
      opened={opened}
      onClose={onClose}
    >
      <Flex direction="column" gap="xs">
        <Text color="dark" fz="0.875rem">
          Are you sure you want to {shouldUnarchive ? 'unarchive' : 'archive'} the condition (
          <strong>{condition.displayConditionName}</strong>)?
        </Text>

        <Flex align="center" justify="flex-end" gap="xs">
          <Button onClick={onClose} color="gray.7" variant="subtle">
            Cancel
          </Button>

          <Button color="red" loading={isUpdating} onClick={handleArchiveOrUnarchive}>
            {shouldUnarchive ? 'Unarchive' : 'Archive'}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export const ArchiveConditionModal = memo(ArchiveConditionModalBase);
