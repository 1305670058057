import { memo, useMemo } from 'react';
import { Badge, Box, Checkbox, Flex, Highlight, Tooltip } from '@mantine/core';

import { MedicalConditionMenuItemProps } from './MedicalConditionMenuItem.types';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { useElementSize } from '@mantine/hooks';
import { isConditionMerging, isLegacyCondition } from '@/pageAI/services/medicalConditions';
import { ServiceConnectedBadge } from '../ServiceConnectedBadge';
import { ClaimStatusIndicator } from '../claimStatus/ClaimStatusIndicator';
import {
  isReviewingIncrementalChangesSelector,
  selectedSimpleConditionSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { ConditionActionMenu } from '../actions/ConditionActionMenu';

const MedicalConditionMenuItemBase = ({
  medicalCondition,
  highlightKeywords = [],
  selected = false,
  highlighted = false,
  bolded = false,
  newlyAdded = false,
  checkboxMode = false,
  disabled = false,
  hideStatusIndicator = false,
  onSelect,
}: MedicalConditionMenuItemProps) => {
  const { ref } = useElementSize<HTMLDivElement>();

  const selectedCondition = useUnifiedStore(selectedSimpleConditionSelector);
  const isReviewing = useUnifiedStore(isReviewingIncrementalChangesSelector);

  const isOverflowed = ref.current?.scrollWidth > ref.current?.offsetWidth;

  const isMerging = isConditionMerging(medicalCondition);
  const isLegacy = useMemo(() => isLegacyCondition(medicalCondition), [medicalCondition]);

  if (isMerging) return null;

  let textContent = (
    <Highlight
      ref={ref}
      highlight={highlightKeywords}
      className="condition-name"
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {medicalCondition.displayConditionName}
    </Highlight>
  );

  if (isOverflowed)
    textContent = (
      <Tooltip label={medicalCondition.displayConditionName} withinPortal position="top-start" openDelay={500}>
        {textContent}
      </Tooltip>
    );

  const content = (
    <MenuItem
      key={medicalCondition.id}
      onClick={() => {
        if (disabled) return;

        onSelect?.(medicalCondition);
      }}
      sx={(theme) => ({
        border: `1px solid transparent`,
        '.actions': {
          opacity: 0,
          transition: 'all 0.15s',
        },
        '&:hover': {
          '.actions': {
            opacity: 1,
          },
        },
        ...(disabled && {
          cursor: 'default',
          backgroundColor: 'transparent !important',
        }),
        ...(selected &&
          !checkboxMode && {
            backgroundColor: theme.colors.dark[5],
            color: theme.white,
            transition: 'all 0.1s',
            '&:hover:not([disabled])': {
              backgroundColor: theme.colors.dark[5],
              color: theme.white,
            },
          }),
        ...(highlighted &&
          !selected && {
            border: `1px solid ${theme.colors.dark[4]}`,
            backgroundColor: theme.colors.orange[1],
            color: theme.colors.dark[7],
            transition: 'all 0.1s',
          }),
      })}
    >
      <Flex
        data-current-condition={selectedCondition?.id === medicalCondition.id}
        className="condition-item"
        align="center"
        gap={4}
        sx={(theme) => ({
          width: '100%',
          ...(disabled && { color: theme.colors.dark[2] }),
          ...(bolded
            ? {
                fontWeight: 600,
              }
            : {}),
        })}
      >
        {!hideStatusIndicator && !isReviewing && <ClaimStatusIndicator condition={medicalCondition} />}

        {checkboxMode && <Checkbox checked={selected} size="xs" disabled={disabled} />}

        {textContent}

        <Flex sx={{ flexGrow: 1 }} />

        {!isReviewing ? (
          <Flex align="center" gap={4}>
            <ServiceConnectedBadge condition={medicalCondition} selected={!checkboxMode ? selected : undefined} />

            <ConditionActionMenu condition={medicalCondition} />
          </Flex>
        ) : (
          <>
            {newlyAdded ? (
              <Tooltip label="New condition" withinPortal withArrow>
                <Badge
                  size="xs"
                  sx={{
                    minWidth: 'fit-content',
                  }}
                  variant="filled"
                  color="teal.6"
                >
                  N
                </Badge>
              </Tooltip>
            ) : bolded ? (
              <Tooltip label="Updated condition" withinPortal withArrow>
                <Badge
                  size="xs"
                  sx={{
                    minWidth: 'fit-content',
                  }}
                  variant="filled"
                  color="orange"
                >
                  U
                </Badge>
              </Tooltip>
            ) : null}
          </>
        )}
      </Flex>
    </MenuItem>
  );

  if (isLegacy) {
    return (
      <Flex direction="column" className={isLegacy ? 'legacy-condition' : undefined}>
        <Box className="prefix" />

        {content}
      </Flex>
    );
  }

  return content;
};

export const MedicalConditionMenuItem = memo(MedicalConditionMenuItemBase);
