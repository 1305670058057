import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import { Box, Flex, Text } from '@mantine/core';
import { IconCircleFilled } from '@tabler/icons-react';

import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { MedicalConditionItemAsSummary } from '../../MedicalConditionItemAsSummary';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { ConditionType } from '@/pageAI/gql/graphql';
import { waitUntilTruthy } from '@/shared/utils/boolean';

interface PrintableConditionSummaryProps {}

export interface PrintableConditionSummaryInstance {
  printCondition: (condition: MedicalConditionItem) => Promise<void>;
  finish: () => void;
}

// TODO: Refactor this component
const PrintableConditionSummaryBase = (
  props: PrintableConditionSummaryProps,
  ref: React.ForwardedRef<PrintableConditionSummaryInstance>,
) => {
  const client = useSelectedClient(true);

  const claimedConditionsCount = useRef(0);
  const potentialConditionsCount = useRef(0);

  const [currentRenderingClaimedCondition, setCurrentRenderingClaimedCondition] = useState<MedicalConditionItem | null>(
    null,
  );
  const [currentRenderingPotentialCondition, setCurrentRenderingPotentialCondition] =
    useState<MedicalConditionItem | null>(null);

  const renderCondition = (condition: MedicalConditionItem | null, index: number) => {
    const conditionGroupTitle =
      condition?.conditionType === ConditionType.Claimed ? 'Claimed Conditions' : 'Potential Conditions';

    if (!condition) return null;

    return (
      <Flex direction="column" key={condition.id} data-condition-id={condition.id}>
        <Flex direction="column" mt="lg" mb="xs">
          <Text fw={600} fz="0.75rem" color="dark.3" component="span" mb={-4}>
            {conditionGroupTitle.replace('Conditions', 'Condition')} {index + 1}
            {condition.rating && <>&nbsp;(SC – {condition.rating})</>}
          </Text>

          <Text fw={600} component="span">
            <IconCircleFilled size={10} /> {condition.displayConditionName}
            {condition.diagnosticCode && (
              <Text color="dark" component="span" fw={600}>
                &nbsp;(DC: {condition.diagnosticCode})
              </Text>
            )}
          </Text>
        </Flex>

        <MedicalConditionItemAsSummary medicalCondition={condition} />
      </Flex>
    );
  };

  useImperativeHandle(
    ref,
    () => ({
      finish: () => {
        setCurrentRenderingClaimedCondition(null);
        setCurrentRenderingPotentialCondition(null);
      },
      printCondition: async (condition: MedicalConditionItem) => {
        const conditionGroupContainerElement = document.getElementById(
          condition.conditionType === ConditionType.Claimed
            ? 'printable-claimed-conditions-container'
            : 'printable-potential-conditions-container',
        );

        if (!conditionGroupContainerElement) return;

        const conditionsContainerElement = conditionGroupContainerElement.querySelector(
          '.conditions-container',
        ) as HTMLDivElement | null;

        if (!conditionsContainerElement) return;

        if (condition.conditionType === ConditionType.Claimed) {
          claimedConditionsCount.current++;
          setCurrentRenderingClaimedCondition(condition);
        } else {
          potentialConditionsCount.current++;
          setCurrentRenderingPotentialCondition(condition);
        }

        await waitUntilTruthy(
          () => !!conditionsContainerElement?.querySelector(`[data-condition-id="${condition.id}"]`),
          20,
        );

        const conditionElement = conditionsContainerElement.querySelector(
          `[data-condition-id="${condition.id}"]`,
        ) as HTMLDivElement | null;

        if (!conditionElement) return;

        conditionsContainerElement.appendChild(conditionElement.cloneNode(true));
      },
    }),
    [],
  );

  const renderConditions = (conditionType: ConditionType) => {
    const conditionGroupTitle = conditionType === ConditionType.Claimed ? 'Claimed Conditions' : 'Potential Conditions';

    const skipRendering =
      (conditionType === ConditionType.Claimed && claimedConditionsCount.current === 0) ||
      (conditionType === ConditionType.Others && potentialConditionsCount.current === 0);

    return (
      <>
        {!skipRendering && (
          <Flex align="center" justify="center" gap="sm" mt="xs" mb={-8}>
            <Box sx={(theme) => ({ flexGrow: 1, height: 1, border: `1px solid ${theme.colors.gray[5]}` })} />

            <Text fz="1rem" fw={600}>
              {conditionGroupTitle}
            </Text>

            <Box sx={(theme) => ({ flexGrow: 1, height: 1, border: `1px solid ${theme.colors.gray[5]}` })} />
          </Flex>
        )}

        <Flex direction="column" className="conditions-container">
          {renderCondition(
            conditionType === ConditionType.Claimed
              ? currentRenderingClaimedCondition
              : currentRenderingPotentialCondition,
            (conditionType === ConditionType.Claimed
              ? claimedConditionsCount.current
              : potentialConditionsCount.current) - 1,
          )}
        </Flex>
      </>
    );
  };

  return (
    <Flex
      id="printable-condition-summary-container"
      pt={4}
      px="xl"
      sx={{
        code: {
          fontFamily: 'unset',
          textDecoration: 'underline',
          fontWeight: 500,
        },
        'div.condition-summary-section': {
          overflow: 'unset',
        },
        '.extra-action--bookmark': {
          display: 'none',
        },
        '.unprintable': {
          display: 'none',
        },
      }}
    >
      <Flex direction="column">
        <Flex direction="column">
          <Text fw={600} color="dark.4" fz="1.125rem" align="center">
            Condition Summary
          </Text>

          <Text fw={500} color="dark.2" align="center" mt={-2}>
            {getClientDisplayName(client)}
          </Text>
        </Flex>

        <Flex direction="column" id="printable-claimed-conditions-container">
          {renderConditions(ConditionType.Claimed)}
        </Flex>

        <Flex direction="column" id="printable-potential-conditions-container">
          {renderConditions(ConditionType.Others)}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const PrintableConditionSummary = memo(forwardRef(PrintableConditionSummaryBase));
