import { memo, useMemo } from 'react';
import { Button } from '@mantine/core';

import {
  isReviewingIncrementalChangesSelector,
  setIsReviewingIncrementalChanges,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { useConditionSummaryDeltas } from '@/pageAI/hooks/conditions/useClientMedicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { shouldReviewIncrementalChanges } from '@/pageAI/services/medicalConditions/conditionVersionReview.services';
import { useIncrementalSummaryFeatureFlag } from '@/pageAI/hooks/featureFlags/useIncrementalSummaryFeatureFlag';

interface ReviewNewVersionButtonProps {}

const ReviewNewVersionButtonBase = (props: ReviewNewVersionButtonProps) => {
  const client = useSelectedClient(true);
  const { enabled } = useIncrementalSummaryFeatureFlag();

  const isReviewing = useUnifiedStore(isReviewingIncrementalChangesSelector);
  const { conditionSummaryDeltas } = useConditionSummaryDeltas({ clientId: client.id });

  const shouldReview = useMemo(() => shouldReviewIncrementalChanges(conditionSummaryDeltas), [conditionSummaryDeltas]);

  if (!enabled) return null;

  if (isReviewing) {
    return (
      <Button size="xs" color="orange.6" variant="light" mr={4} onClick={() => setIsReviewingIncrementalChanges(false)}>
        Finish reviewing
      </Button>
    );
  }

  if (!shouldReview) return null;

  return (
    <Button size="xs" color="orange.6" variant="light" mr={4} onClick={() => setIsReviewingIncrementalChanges(true)}>
      Review changes
    </Button>
  );
};

export const ReviewNewVersionButton = memo(ReviewNewVersionButtonBase);
